
  import userController from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/components/RecentPostsFeed/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  
var initI18n = null;

  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://baf30a2b91654c5a840931f0137bed30@sentry.wixpress.com/1696',
    id: 'baf30a2b91654c5a840931f0137bed30',
    projectName: 'communities-forum-client',
    teamName: 'forum',
    errorMonitor: true,
  };

  var experimentsConfig = {"centralized":true,"scopes":["wix-forum"]};

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var biConfig = null;

  var defaultTranslations = null;

  var fedopsConfig = {"enabled":true};

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "communities-forum-client",
    componentName: "RecentPostsFeed",
    appDefinitionId: "14724f35-6794-cd1a-0244-25fd138f9242",
    componentId: "151bd179-dfe6-3ca3-399c-ee97957f93a2",
    projectName: "communities-forum-client",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: true,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/components/RecentPostsFeed/controller.ts",
  };

  export const controller = _controller
  export default controller;
